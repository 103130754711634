<template>
  <div class="lecture-detail">
    <navbar bg="transparent" title="学堂详情" />
    <div v-loading="loading" class="lecture-detail-inner">
      <img class="thumb" :src="detail.image" alt="" />
      <div class="content-inner">
        <div class="title">
          {{ detail.title || "" }}
        </div>
        <div class="desc">讲师：<span>曾子文化</span></div>
        <div class="desc">
          浏览数：<span>{{ detail.view_count }}次</span>
        </div>
        <div class="bottom">
          <div class="desc">
            发布时间：
            <span>{{ $format(detail.cdatetime, "YYYY-MM-DD") }}</span>
          </div>
          <div class="view-count">
            价格
            <span class="red">{{
              detail.price == 0 ? "免费" : `¥${detail.price}`
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="menu">
      <div class="type-tabs">
        <div
          v-for="item in tabs"
          class="tab"
          :key="item.route"
          :class="{ active: isActiveTab(item) }"
          @click="changeType(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <div v-show="isActiveTab(tabs[0])" class="menu-list">
        <div
          class="play-list-item"
          v-for="item in playList"
          :key="item.id"
          @click="handleToLecture(item.id)"
        >
          <img :src="item.image" alt="" class="thumb" />
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="duration">
              {{ $format(item.cdatetime, "YYYY-MM-DD") }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="isActiveTab(tabs[1])"
        class="intro"
        v-html="detail.description"
      ></div>
    </div>
    <div class="bottom-bar">
      <!-- <div class="normal-btn">
        <van-icon name="comment-o" size="30" color="#CA0A15" />
        <span>咨询</span>
      </div> -->
      <div class="normal-btn" @click="copyUrl">
        <van-icon name="share-o" size="30" color="#CA0A15" />
        <span>分享</span>
      </div>
      <div class="play-btn" @click="handlePlay">
        开始学习
      </div>
    </div>
    <pay-dialog
      :type="3"
      :bookId="detail.id"
      :bookPrice="detail.price"
      ref="pay-dialog"
      @success="fetchDetail"
    />
    <tips ref="tips" title="提示">
      <img class="icon" src="@/assets/warn.png" alt="" />
      <div class="tips-title">是否消耗次数观看？</div>
      <div class="tips-desc">
        如继续观看，则将消耗课程观看名额
        <span style="font-size: 12px"
          >(<span class="remain">{{ userInfo.last_lec_count }}</span
          >/{{ userInfo.last_lec_count + userInfo.lec_view_count }})</span
        >
      </div>
      <button class="confirm-next-btn" @click="confirmNext">继续</button>
    </tips>
  </div>
</template>

<script>
import Navbar from "@/components/h5/Navbar";
import { mapState } from "vuex";
import { Icon } from "vant";
import otherApis from "@/apis/other";
import PayDialog from "@/components/h5/PayDialog";
import Tips from "@/components/h5/Tips";

const TABS = [
  {
    label: "课程目录",
    route: "play-list",
    left: "58px"
  },
  {
    label: "简介",
    route: "intro",
    left: "144px"
  }
];

function copyTextToClipboard(text) {
  const copyFrom = document.createElement("textarea");
  copyFrom.textContent = text;
  document.body.appendChild(copyFrom);
  copyFrom.select();
  document.execCommand("copy");

  copyFrom.blur();

  document.body.removeChild(copyFrom);
}

export default {
  name: "LectureDetail",
  components: {
    Navbar,
    PayDialog,
    Tips,
    [Icon.name]: Icon
  },
  data() {
    return {
      tabs: TABS,
      activeTab: TABS[0],
      detail: {},
      playList: [],
      loading: false
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab.route === tab.route;
    },
    async fetchDetail() {
      this.loading = true;
      try {
        const {
          data: { course_info, isbuy, isduihuan }
        } = await otherApis.courseDetail({
          id: this.$route.params.id
        });

        this.detail =
          {
            ...course_info,
            isbuy,
            isduihuan
          } || {};
        this.fetchPlayList(1);
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async fetchPlayList(page) {
      this.page = page;
      try {
        const {
          data: { course_list, max_page }
        } = await otherApis.courseIndex({ cat_id: this.detail.cat_id });
        this.maxPage = max_page;
        this.playList = course_list;
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
    },

    async fetchSourceUrl() {
      try {
        const {
          data: { source_path }
        } = await otherApis.courseDetailUrl({
          id: this.detail.id
        });
        console.log(source_path);

        return source_path;
      } catch (error) {
        console.log(error);
      }
    },

    async handlePlay() {
      if (
        parseFloat(this.detail.price) === 0 ||
        this.detail.isbuy ||
        this.detail.isduihuan
      ) {
        const url = await this.fetchSourceUrl();
        window.open(url);
      } else {
        if (!this.userInfo.isLogin) {
          // 未登陆
          console.log("为登陆");
          this.bus.emit("login");
        } else {
          // 已登陆 显示购买或者消耗次数弹窗
          console.log("fetch");
          // const url = await this.fetchSourceUrl();
          // window.open(url);
          // this.fetchPdfs(detail.view_page);
          this.showPayDialog();
        }
      }
      // if (this.detail.type === 1) {
      //   window.open(this.detail.source_url);
      // } else {

      // }
    },

    async confirmNext() {
      this.$refs["tips"].hide();
      const url = await this.fetchSourceUrl();
      window.open(url);
    },

    showPayDialog() {
      if (this.userInfo.last_lec_count > 0) {
        // 是vip 使用vip次数
        this.$refs["tips"].show();
      } else {
        // 购买
        this.$refs["pay-dialog"].show();
      }
    },

    handleToLecture(id) {
      if (id === this.detail.id) return;
      this.$router.push(`/h5/lecture/${id}`);
    },

    changeType(tab) {
      this.activeTab = tab;
      // this.catId = tab.route;
      // this.currApi =
      //   type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      // this.refreshList();
    },

    copyUrl() {
      copyTextToClipboard(location.href);
      this.$toast("链接已复制到剪切板，快去分享给好友吧");
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.params.id !== oldVal.params.id) {
        this.fetchDetail();
      }
    }
  }
};
</script>

<style lang="scss" scope>
.lecture-detail {
  background-color: #fff;
  /* prettier-ignore */
  min-height: calc(100vh - 50PX);
  // box-sizing: border-box;
  // display: flex;
  // justify-content: center;
  // padding: 15px 0;
  // height: calc(100vh - 70px);

  .lecture-detail-inner {
    display: flex;
    flex-flow: column nowrap;
    width: 1045px;
    margin: 0 auto;
    box-sizing: border-box;
    background: #f7f7f7;
    border-radius: 10px;

    .thumb {
      object-fit: cover;
      height: 600px;
    }

    .content-inner {
      padding: 40px;
    }

    .desc {
      font-size: 30px;
      color: #333;
      overflow: hidden;
      margin-top: 30px;
      // margin-top: 30px;

      span {
        color: #666;
      }
    }

    .title {
      font-size: 56px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .content {
      // font-size: 14px;
      line-height: 1.4;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 60px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      font-size: 30px;
      margin-top: 0px;

      .red {
        color: #ca0a15;
        font-size: 48px;
      }
    }
  }
}

.menu {
  padding: 40px;

  .menu-list {
    margin-top: 20px;
    .play-list-item {
      width: 1045;
      height: 200px;
      background: #f7f7f7;
      border-radius: 4px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      cursor: pointer;

      .thumb {
        height: 160px;
        width: 160px;
        object-fit: cover;
      }

      .info {
        margin-left: 40px;

        .title {
          font-size: 36px;
          color: #333333;
        }

        .duration {
          font-size: 30px;
          color: #666666;
          margin-top: 40px;
        }
      }
    }
  }

  .intro {
    font-size: 40px;
  }
}

.type-tabs {
  display: flex;

  .tab {
    position: relative;
    width: 240px;
    height: 90px;
    background: #f7f7f7;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3px;
    margin-right: 10px;
    font-size: 44px;
    color: #666666;
    cursor: pointer;

    &.active {
      opacity: 1;
      color: #ffffff;
      background-color: #ca0a15;
    }
  }
}

.bottom-bar {
  height: 200px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .normal-btn {
    width: 25%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    color: #ca0a15;
  }

  .play-btn {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ca0a15;
    color: #fff;
  }
}
</style>
